.sf-tooltip-container {
  position: relative;
  &:not(:hover) {
    .sf-tooltip {
      visibility: hidden;
      opacity: 0;
      transform: translateX(10%) translateY(-50%);
      transition: all 0.25s ease 0.1s;
      @media screen and (max-width: 768px) {
        transform: translateX(0) translateY(-50%);
      }
    }
  }
  .sf-tooltip {
    z-index: 2;
    transition: all 0.25s ease;
    opacity: 1;
    transition: all 0.25s ease;
    max-width: 310px;
    width: max-content;
    position: absolute;
    top: 50%;
    left: calc(100% + 14px);
    transform: translateX(0) translateY(-50%);
    padding: 16px;
    background-color: #fff;
    border: 1px solid #393a97;
    color: #393a97;
    box-shadow: 0px 2px 4px rgba(57, 58, 151, 0.25);
    @media screen and (max-width: 768px) {
      position: fixed;
      top: auto;
      bottom: 16px;
      left: 16px;
      right: 16px;
      max-width: unset;
      width: auto;
      color: #fff;
      background-color: #393a97;
      border: none;
      transform: translateX(0) translateY(0);
      &::after {
        display: none;
      }
    }
    &::after {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateX(-9px) translateY(-50%) rotate(45deg);
      content: "";
      width: 16px;
      height: 16px;
      background-color: #fff;
      border-left: 1px solid #393a97;
      border-bottom: 1px solid #393a97;
    }
  }
}
