@font-face {
  font-family: Avenir;
  font-weight: 400;
  src: url("../fonts/AvenirLTStd-Roman.otf") format("truetype");
}
.sf-typo {
  &.\--48-oswald {
    font-family: Oswald;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 71px;
    letter-spacing: 0em;
  }
  &.\--24-avenir {
    font-family: Avenir;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
  }
  &.\--18-avenir {
    font-family: Avenir;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
  }
  &.\--16-avenir {
    font-family: Avenir;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
  }
}
