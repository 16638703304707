.sf-button {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  padding: 11px 16px;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  transition: all 0.25s ease;
  &.--primary {
    background: #393a97;
    color: #fff;
    box-shadow: 0 2px 4px rgba(57, 58, 151, 0.25);
    border: 1px solid #393a97;
    & .sf-button-spinner {
      stroke: #fff;
    }
  }
  &.--secondary {
    background: #fff;
    border: 1px solid #393a97;
    box-shadow: 0px 2px 4px rgba(57, 58, 151, 0.25);
    color: #393a97;
    & .sf-button-spinner {
      stroke: #393a97;
    }
  }
  &.--loading {
    pointer-events: none;
    padding-left: 57px;
    & .sf-button-spinner {
      visibility: visible;
      opacity: 1;
    }
  }
  & .sf-button-spinner {
    transition: all 0.25s ease;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(8px, -50%);
    width: 25px;
    animation: sf-button-spinner 2s linear infinite;
    stroke: #393a97;
    stroke-width: 2;
    stroke-dasharray: 25;
    fill: transparent;
  }
  > img:last-child {
    margin-left: 8px;
  }
}

@keyframes sf-button-spinner {
  from {
    stroke-dashoffset: 25;
  }
  to {
    stroke-dashoffset: 75;
  }
}
