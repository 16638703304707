@import url("./reboot.css");
@import url("./framework.scss");
@import url("./typo.scss");
@import url("./footer.css");
@import url("./tooltip.scss");
@import url("./button.scss");
@import url("./centerer.css");
@import url("./colors.scss");
@import url("./divider.scss");

::-webkit-scrollbar {
  background-color: transparent;
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #d9d6dd;
  border-radius: 6px;
}

html {
  overflow-y: scroll;
}

html,
body {
  padding: 0;
  margin: 0;
  color: #18192d;
  scroll-behavior: smooth;
  font-size: 16px;
  font-family: Avenir;
}

body {
  user-select: none;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

button {
  padding: 0;
}

button,
.clickable {
  cursor: pointer;
}

/** LAYOUT **/

.no-overflow {
  overflow: hidden;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.centerer {
  max-width: 1200px;
  margin: auto;
}

.max-w-600 {
  max-width: 600px;
}

@media screen and (max-width: 768px) {
  .max-w-600 {
    max-width: none;
  }
}

.padding-50 {
  padding: 50px;
}

@media screen and (max-width: 768px) {
  .padding-50 {
    padding: 18px;
  }
}

.margin-50-h {
  margin-left: 50px;
  margin-right: 50px;
}

@media screen and (max-width: 768px) {
  .margin-50-h {
    margin-left: 18px;
    margin-right: 18px;
  }
}

.m-8 {
  margin: 8px;
}

.margin-auto {
  margin: auto;
}

.fit-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.fit-cover {
  object-fit: cover;
}

.fill-width {
  width: 100%;
}

.fill-height {
  height: 100%;
}

.vh {
  height: 100vh;
}

.relative {
  position: relative;
}

.flex {
  display: flex;
}

.flex.space-between {
  justify-content: space-between;
}

.flex.column {
  flex-direction: column;
}

.flex.center-items {
  align-items: center;
}

.flex.justify-center {
  justify-content: center;
}

.flex.justify-end {
  justify-content: flex-end;
}

.flex.wrap {
  flex-wrap: wrap;
}

.flex > .--no-shrink {
  flex-shrink: 0;
}

.flex > .grow {
  flex-grow: 1;

  /* flex-basis: 0; */
}

.flex > .self-center {
  align-self: center;
}

/** MULTI-DEVICE **/

@media screen and (max-width: 768px) {
  .desktop-only {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .mobile-only {
    display: none;
  }
}

.button-scroll {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: #fff;
  border: 1px solid #f7f7f8;
  border-radius: 24px;
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(57, 58, 151, 0.25);
}
