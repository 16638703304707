.sf-color {
  &.--blue-1,
  &.--light-blue {
    color: #4296c4;
  }
  &.--blue-2 {
    color: #393a97;
  }
  &.--purple {
    color: #622878;
  }
  &.--white {
    color: #ffffff;
  }
  &.--grey-1 {
    color: #f7f7f8;
  }
  &.--grey-2 {
    color: #d9d6dd;
  }
  &.--grey-3 {
    color: #828085;
  }
  &.--grey-blue {
    color: #e7e7f1;
  }
  &.--red {
    color: #eb5757;
  }
}
