// Configuration

$prefix: "sf-";

$sizes: (
  "none": 0px,
  "sm": 8px,
  "md": 16px,
  "lg": 32px,
  "xl": 64px,
);

$breakpoints: (
  "sm": 425px,
  "md": 768px,
  "lg": 1024px,
);

/* Features
.sf-container
.sf-grid
.sf-text
.sf-pos
--- */

$directions: (
  "top": "t",
  "right": "r",
  "bottom": "b",
  "left": "l",
);

// @use "sass:map";

.#{$prefix}container {
  @mixin container($propertyName, $propertyShort, $nameSize, $size) {
    &.--#{$propertyShort}-#{$nameSize} {
      #{$propertyName}: $size;
    }
    &.--#{$propertyShort}-v-#{$nameSize} {
      #{$propertyName}-top: $size;
      #{$propertyName}-bottom: $size;
    }
    &.--#{$propertyShort}-h-#{$nameSize} {
      #{$propertyName}-left: $size;
      #{$propertyName}-right: $size;
    }
    @each $direction, $short in $directions {
      &.--#{$propertyShort}-#{$short}-#{$nameSize} {
        #{$propertyName}-#{$direction}: $size;
      }
    }
  }
  @mixin containerResponsive(
    $prefixBp,
    $propertyName,
    $propertyShort,
    $nameSize,
    $size
  ) {
    &.--#{$prefixBp}\:#{$propertyShort}-#{$nameSize} {
      #{$propertyName}: $size;
    }
    &.--#{$prefixBp}\:#{$propertyShort}-v-#{$nameSize} {
      #{$propertyName}-top: $size;
      #{$propertyName}-bottom: $size;
    }
    &.--#{$prefixBp}\:#{$propertyShort}-h-#{$nameSize} {
      #{$propertyName}-left: $size;
      #{$propertyName}-right: $size;
    }
    @each $direction, $short in $directions {
      &.--#{$prefixBp}\:#{$propertyShort}-#{$short}-#{$nameSize} {
        #{$propertyName}-#{$direction}: $size;
      }
    }
  }
  @each $propertyName, $propertyShort in ("margin": "m", "padding": "p") {
    @each $name, $size in $sizes {
      @include container($propertyName, $propertyShort, $name, $size);
    }
    @each $prefixBp, $widthBp in $breakpoints {
      @media screen and (max-width: $widthBp) {
        @each $name, $size in $sizes {
          @include containerResponsive(
            $prefixBp,
            $propertyName,
            $propertyShort,
            $name,
            $size
          );
        }
      }
    }
  }
}

.#{$prefix}grid {
  display: -ms-grid;
  display: grid;
  @mixin grid-gap($prefixBp: "") {
    @each $name, $size in $sizes {
      &.--#{$prefixBp}gap-#{$name} {
        gap: $size;
      }
    }
  }
  @include grid-gap("");
  @each $size, $value in $breakpoints {
    @media screen and (max-width: $value) {
      @include grid-gap(#{$size}\:);
    }
  }
  @mixin grid-template($i) {
    -ms-grid-columns: repeat($i, 1fr);
    grid-template-columns: repeat($i, 1fr);
    &.--auto {
      -ms-grid-columns: repeat($i, auto);
      grid-template-columns: repeat($i, auto);
    }
  }
  @for $i from 1 through 4 {
    &.--col-#{$i} {
      @include grid-template($i);
    }
  }
  @each $size, $value in $breakpoints {
    @media screen and (max-width: $value) {
      @for $i from 1 through 4 {
        &.--#{$size}\:col-#{$i} {
          @include grid-template($i);
        }
      }
    }
  }
}

.#{$prefix}text {
  &.--capitalize {
    text-transform: capitalize;
  }
  &.--uppercase {
    text-transform: uppercase;
  }
  &.--underline {
    text-transform: underline;
  }
  @mixin rule-text($prefixBp: "") {
    &.--#{$prefixBp}left {
      text-align: left;
    }
    &.--#{$prefixBp}center {
      text-align: center;
    }
    &.--#{$prefixBp}right {
      text-align: right;
    }
  }
  @include rule-text();
  @each $size, $value in $breakpoints {
    @media screen and (max-width: $value) {
      @include rule-text(#{$size}\:);
    }
  }
}

.#{$prefix}pos {
  @each $position in ("relative", "absolute", "fixed", "sticky") {
    &.--#{$position} {
      position: #{$position};
      @each $direction, $shorthand in $directions {
        &.--#{$shorthand} {
          #{$direction}: 0;
        }
      }
      &.--fill {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
}

.#{$prefix}flex {
  display: flex;
  @mixin flex($prefixBp: "") {
    &.--#{$prefixBp}row {
      flex-direction: row;
      &.--#{$prefixBp}reverse {
        flex-direction: row-reverse;
      }
    }
    &.--#{$prefixBp}col {
      flex-direction: column;
      &.--#{$prefixBp}reverse {
        flex-direction: column-reverse;
      }
    }
    &.--#{$prefixBp}inline {
      display: inline-flex;
    }
    &.--#{$prefixBp}align-baseline {
      align-items: baseline;
    }
    &.--#{$prefixBp}align-center {
      align-items: center;
    }
    &.--#{$prefixBp}align-stretch {
      align-items: stretch;
    }
    &.--#{$prefixBp}align-end {
      align-items: flex-end;
    }
    &.--#{$prefixBp}wrap {
      flex-wrap: wrap;
    }
    &.--#{$prefixBp}justify-center {
      justify-content: center;
    }
    &.--#{$prefixBp}justify-end {
      justify-content: flex-end;
    }
    &.--#{$prefixBp}justify-space-evenly {
      justify-content: space-evenly;
    }
    &.--#{$prefixBp}justify-space-between {
      justify-content: space-between;
    }
  }
  @include flex();
  @each $size, $value in $breakpoints {
    @media screen and (max-width: $value) {
      @include flex(#{$size}\:);
    }
  }
  > .--grow {
    flex-grow: 1;
    flex-basis: 0;
  }
  > .--no-shrink {
    flex-basis: auto;
    flex-shrink: 0;
  }
  > .--self-center {
    align-self: center;
  }
  > .--self-stretch {
    align-self: stretch;
  }
}

// @each $size, $value in $breakpoints {
//     @media screen and (max-width: $value){
//       .#{$size}-only {
//         display: none;
//       }
//     }
// }

// @function reverse($list) {
//    $result: ();
//    @for $i from length($list)*-1 through -1 {
//         $result: append($result, nth($list, abs($i)));
//    }
//    @return $result;
// }

// $breakpointsReversed: reverse($breakpoints);
// map.set($breakpointsReversed, 'infinity', 'infinity');
// @each $shorthand, $breakpoint in $breakpointsReversed {
//     .#{$shorthand}{
//         width: $breakpoint;
//     }
// }
