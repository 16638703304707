footer {
  background-color: #0a0d13;
  color: #fff;
  padding: 12px 50px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  grid-column: 1 / 3;
}

@media screen and (max-width: 768px) {
  footer {
    padding-left: 20px;
    padding-right: 20px;
  }
}
